<template>
  <span class="add-family-message" v-if="canChange">
    <b-modal
      dialog-class="modal-xl"
      id="add-family-message"
      cancel-title="Annuler"
      :ok-disabled="!isValid()"
      @ok.prevent="onOk"
      @hidden="onHidden"
      ok-variant="primary"
      :ok-title="userMessage ? 'Modifier' : 'Ajouter'"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-calendar"></i>
          Portail Familles
          <span v-if="userMessage">Modification d'un message aux familles</span>
          <span v-else>Ajout d'un message aux familles</span>
        </b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group
            id="youth-home-group"
            :label="youthHomeLabel"
            label-for="youth-home"
          >
            <b-form-select
              id="youth-home"
              v-model="selectedYouthHome"
              required
              >
              <b-form-select-option value="0">Tous</b-form-select-option>
              <b-form-select-option
                :value="item.number"
                v-for="item in youthHomes"
                :key="item.id"
              >
                {{ item.baseName() }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            id="order-group"
            label="Ordre"
            label-for="order"
          >
            <b-form-input type="number" v-model="order" id="order" step="1" min="0"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="opening-at-group"
            label="Ouverture"
            label-for="opening-at"
          >
            <datetime-input id="opening-at" v-model="openingAt"></datetime-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="closing-at-group"
            label="Fermeture"
            label-for="closing-at"
          >
            <datetime-input id="closing-at" v-model="closingAt"></datetime-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="text-group"
            label="Message"
            label-for="text"
            description="au moins 10 caractères"
          >
            <b-textarea v-model="text" :rows="textLines" placeholder="Votre message"></b-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeYouthHome } from '@/types/youth'
import { BackendApi } from '@/utils/http'
import { existsIn } from '@/utils/arrays'
import { countCharacter } from '@/utils/strings'
import store from '@/store'
import DatetimeInput from '@/components/Controls/DatetimeInput.vue'

export default {
  name: 'add-family-message',
  components: {
    DatetimeInput,
  },
  mixins: [BackendMixin],
  props: {
    userMessage: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      youthHomes: [],
      selectedYouthHome: 0,
      text: '',
      errorText: '',
      order: 0,
      openingAt: null,
      closingAt: null,
    }
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    textLines() {
      return Math.max(5, countCharacter(this.text, '\n') + 1)
    },
    canChange() {
      if (this.userMessage) {
        return this.hasPerm('portal.change_usermessage')
      } else {
        return this.hasPerm('portal.add_usermessage')
      }
    },
  },
  watch: {
    userMessage: function(userMessage) {
      if (userMessage) {
        this.text = userMessage.text
        this.selectedYouthHome = userMessage.youthHomeNumber
        this.order = userMessage.order
        this.openingAt = userMessage.openingAt
        this.closingAt = userMessage.closingAt
      } else {
        this.text = ''
        this.selectedYouthHome = 0
        this.order = 0
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    isValid() {
      if (this.selectedYouthHome === null) {
        return false
      }
      if (this.text.length < 10) {
        return false
      }
      return true
    },
    onHidden() {
      this.$emit('hidden')
    },
    async onOk() {
      this.errorText = ''
      try {
        const userMessageId = this.userMessage ? this.userMessage.id : 0
        let url = '/api/portal/user-messages/'
        if (userMessageId) {
          url += userMessageId + '/'
        }
        const method = userMessageId ? 'patch' : 'post'
        const backendApi = new BackendApi(method, url)
        let data = {
          youth_home_number: this.selectedYouthHome,
          text: this.text,
          order: this.order,
          opening_at: this.openingAt || null,
          closing_at: this.closingAt || null,
        }
        await backendApi.callApi(data)
        if (userMessageId) {
          await this.addSuccess('Le message a été modifié')
        } else {
          await this.addSuccess('Le message a été ajouté')
        }
        this.$emit('done')
        this.$bvModal.hide('add-family-message')
      } catch (err) {
        this.errorText = this.getErrorText(err)
        this.addError(this.getErrorText(err))
      }
    },
    async loadYouthHomes() {
      this.youthHomes = []
      let url = '/api/youth/active-youth-homes/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        const youthHomes = resp.data.map(elt => makeYouthHome(elt))
        for (const elt of youthHomes) {
          if (!existsIn([elt.number], this.youthHomes.map(elt2 => elt2.number))) {
            this.youthHomes.push(elt)
          }
        }
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
  },
  async mounted() {
    await this.loadYouthHomes()
  },
}
</script>
<style lang="less">
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
</style>
